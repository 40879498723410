:root {

  --ligth-green: #183334;

  --dark-green: #122526;

  --btn-green: #22a663;

  --btn-green-hover: #24b06a;

  --title-color: #c7a659;

  --second-title-color: #f7e7ca;

  --title-shadow: #4f4120e6;

  --header-color: #b30000;

  --areas-card: #8686866d;

  --h1-card: #c7a6591c;

  --box-shadow: #0000008c;

  --background-color: #00000080;
}

.home:before {
  background-image: url('../images/home_background.png');
  background-position: -300px center;
  background-repeat: no-repeat;
  background-size: cover;
  content: '';
  filter: blur(2px);
  inset: 0;
  position: absolute;
  z-index: -1;
}

.home {
  align-items: center;
  background-color: var(--background-color);
  display: flex;
  flex-direction: column;
  height: 93vh;
  justify-content: center;
}

.home_title {
  font-size: 18vw;
  text-align: center;
  text-shadow: 2px 2px 2px var(--title-shadow);
}

.home_title:first-child {
  color: var(--second-title-color);
}

.second-title_line:after {
  bottom: 0;
  color: var(--second-title-color);
  content: '?';
  transform: translate(-100%, 0);
}

.home_subtitle {
  color: var(--second-title-color);
  font-size: 3.5vw;
  font-weight: 400;
  gap: 50px;
  letter-spacing: 6px;
  margin-top: 80px;
  text-align: center;
  text-transform: uppercase;
}

@media ( min-width : 481px ) {

  .home:before {
    background-position: center;
  }

  .home_title {
    font-size: 15vw;
  }

  .home_subtitle {
    font-size: 3vw;
  }
}

@media ( min-width : 769px ) {

  .home {
    height: 100vh;
  }

  .home_title {
    font-size: 12vw;
  }

  .home_subtitle {
    font-size: 2.5vw;
    letter-spacing: 8px;
  }
}

@media ( min-width : 1025px ) {

  .home_title {
    font-size: 9vw;
  }

  .home_subtitle {
    font-size: 2vw;
  }
}

/* @media ( min-width : 1201px ) {
} */
