:root {

  --ligth-green: #183334;

  --dark-green: #122526;

  --btn-green: #22a663;

  --btn-green-hover: #24b06a;

  --title-color: #c7a659;

  --title-shadow: #4f4120d9;

  --header-color: #b30000;

  --areas-card: #8686866d;

  --h1-card: #c7a6591c;

  --box-shadow: #0000008c;

  --background-color: #000000a6;
}

.contactMe {
  align-items: center;
  background-color: var(--dark-green);
  display: flex;
  flex-direction: column;
  height: 70vh;
  justify-content: space-evenly;
  text-align: center;
}

.h1_title {
  font-size: 2.5em;
  max-width: 80vw;
}

.btn_contactMe {
  background-color: var(--btn-green);
  border-radius: 30px;
  color: var(--ligth-green);
  font-weight: 800;
  max-width: 80vw;
  padding: 15px;
  text-decoration: none;

}

.btn_contactMe:hover {
  background-color: var(--btn-green-hover);
  box-shadow: 0 0 12px 3px var(--box-shadow);
}

@media ( min-width : 481px ) {

  .h1_title {
    font-size: 3em;
  }
}

@media ( min-width : 769px ) {

  .h1_title {
    font-size: 3.5em;
  }

  .btn_contactMe {
    font-size: 1.3em;
    padding: 20px 25px;
  }

}

@media ( min-width : 1025px ) {

  .h1_title {
    font-size: 4em;
  }
}

@media ( min-width : 1201px ) {

  .h1_title {
    font-size: 4.5em;
  }
}
