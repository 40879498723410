:root {

  --ligth-green: #183334;

  --dark-green: #122526;

  --btn-green: #22a663;

  --btn-green-hover: #24b06a;

  --title-color: #c7a659;

  --title-shadow: #4f4120d9;

  --header-color: #b30000;

  --areas-card: #8686866d;

  --h1-card: #c7a6591c;

  --box-shadow: #0000008c;

  --background-color: #000000a6;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  color: white;
  font-family: Montserrat, sans-serif;
}

h1 {
  color: var(--title-color);
  font-family: Bebas Neue, sans-serif;
  font-weight: 500;
  text-transform: uppercase;
}

